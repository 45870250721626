import React from "react";
import styled from "styled-components";

const AssignReservations = ({ hotelDetails, chosenLanguage }) => {
	return (
		<AssignReservationsWrapper>
			<div>Hello from AssignReservations</div>
		</AssignReservationsWrapper>
	);
};

export default AssignReservations;

const AssignReservationsWrapper = styled.div``;
