export const relocationArray1 = [
	{
		_id: "65d5f8d4a7ee9f8e4c9c596d",
		hotelName: "ayed hotel (al-saha)",
		hotelAmenities: [],
		hotelFloors: 15,
		overallRoomsCount: 345,
		roomCountDetails: {},
		pricingCalendar: [],
		hotelPhotos: [],
		hotelRating: 3.5,
		parkingLot: false,
		belongsTo: "65d5f83fa7ee9f8e4c9c594b",
		createdAt: "2024-02-21T13:21:24.253+00:00",
		updatedAt: "2024-02-21T13:21:24.253+00:00",
	},
	{
		_id: "65b640a1f33023933c22eba3",
		hotelName: "ayed al nozaha",
		hotelAmenities: [],
		hotelFloors: 15,
		overallRoomsCount: 300,
		roomCountDetails: {},
		hotelRating: 3.5,
		parkingLot: false,
		belongsTo: "65b63eb0f33023933c22eb9d",
		createdAt: "2024-01-28T11:55:13.558+00:00",
		updatedAt: "2024-01-31T06:44:22.920+00:00",
		__v: 0,
		hotelPhotos: [],
		pricingCalendar: [],
	},
	{
		_id: "658c7c02f848bc6562f5c5cc",
		hotelName: "zaer plaza",
		hotelAmenities: [],
		hotelFloors: 11,
		overallRoomsCount: 334,
		roomCountDetails: {},
		hotelRating: 3.5,
		parkingLot: true,
		belongsTo: "658c777af848bc6562f5c5ba",
		createdAt: "2023-12-27T19:33:22.137+00:00",
		updatedAt: "2024-02-28T00:24:41.691+00:00",
		__v: 0,
		hotelPhotos: [],
		pricingCalendar: [],
		subscribed: false,
		subscriptionId: "",
		subscriptionToken: "",
	},
];
