import React from "react";
import styled from "styled-components";

const ActiveClientsSupportCasesHotels = () => {
	return (
		<ActiveClientsSupportCasesHotelsWrapper>
			<div>Active Clients Support Cases Content</div>
		</ActiveClientsSupportCasesHotelsWrapper>
	);
};

export default ActiveClientsSupportCasesHotels;

const ActiveClientsSupportCasesHotelsWrapper = styled.div``;
